import { DocumentFirestore } from "core/models";
import { type AdditionalServiceData, SystemStatus } from "core/typings";
import type { AdditionalServicesType } from "features/additional-service/model";

import store from "global/store";

export class AdditionalServiceEntity extends DocumentFirestore<AdditionalServiceData> {
	static readonly collPath: string = "additional_services";

	vin: string;
	total: number;
	status: SystemStatus;
	services: AdditionalServicesType[];

	createdByName: string;
	createdBy: string;
	createdAt: Date;

	constructor(data: AdditionalServiceData) {
		super(data);

		this.vin = data.vin;
		this.total = data.total;
		this.status = data.status ? data.status : SystemStatus.PENDING;
		this.services = data.services ? data.services : [];

		this.createdByName = data.createdByName
			? data.createdByName
			: store.user.name;
		this.createdBy = data.createdBy ? data.createdBy : store.user.login;
		this.createdAt = data.createdAt ? new Date(data.createdAt) : new Date();
	}

	static convertTimestampsFromFirestore(data) {
		super.convertTimestampsFromFirestore(data);

		data.createdAt = data.createdAt.toDate();
	}
}
