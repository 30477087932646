import type { FileInfoData } from "core/typings";

export class FileInfo {
	// ==================== Class Properties ====================

	// ==================== Instance Properties ====================
	fileName: string;
	fileSize: number;
	fileType: string;
	fileUrl: string;
	fileUploadDate: Date;

	// ==================== Class Methods ====================

	// ==================== Instance Methods ====================
	constructor(data: FileInfoData) {
		this.fileName = data.fileName;
		this.fileSize = data.fileSize;
		this.fileType = data.fileType;
		this.fileUploadDate = new Date(data.fileUploadDate);
		this.fileUrl = data.fileUrl;
	}
}
