// Route
export const HOME_URL = "/home";
export const VEHICLES_URL = "/vehicles";
export const TABLES_URL = "/tables";
export const TABLES_USER_URL = "/tables/user";
export const SETTING_URL = "/setting";
export const BALANCE_URL = "/balance";
export const PAY_BALANCE_URL = "/pay-balance";
export const CARFAX_URL = "/carfax";
export const CHAT_URL = "/chat";

// Domain name project
export const DOMAIN_NAME = "trackyourvin.com";
