import {
	alertController,
	loadingController,
	modalController,
	popoverController,
	toastController,
	type ComponentProps,
} from "@ionic/core";

export async function presentToast(
	message: string,
	kind: "error" | "info" | "success" | "",
	duration = 2000,
): Promise<void> {
	let color: string;
	let icon: string;

	switch (kind) {
		case "info":
			color = "medium";
			icon = "information-circle-outline";
			break;
		case "success":
			color = "success";
			icon = "checkmark-circle-outline";
			break;
		case "error":
			color = "danger";
			icon = "warning-outline";
			break;
	}

	const toast = await toastController.create({
		animated: true,
		icon: icon,
		color: color,
		position: "top",
		duration,
		message: message,
	});
	await toast.present();
	await toast.onWillDismiss();
}

export async function presentAlertConfirm(
	message: string,
	okFunc?: () => void,
	noFunc?: () => void,
) {
	const alert = await alertController.create({
		header: "Confirmation",
		message: message,
		buttons: [
			{
				text: "Yes",
				handler: () => {
					if (okFunc) {
						okFunc();
					}
				},
			},
			{
				text: "Cancel",
				role: "cancel",
				handler: () => {
					if (noFunc) {
						noFunc();
					}
				},
			},
		],
	});
	return await alert.present();
}

export async function presentModal(
	componentName: string,
	componentProp?: ComponentProps,
) {
	const modal = await modalController.create({
		component: componentName,
		cssClass: componentName,
		componentProps: { ...componentProp },
	});
	await modal.present();

	return modal;
}

export const presentPopover = async (
	componentName: string,
	componentProp?: ComponentProps,
	event?: Event,
) => {
	const popover = await popoverController.create({
		component: componentName,
		componentProps: {
			...componentProp,
		},
		cssClass: componentName,
		event: event,
		reference: "event",
	});
	await popover.present();
	return popover;
};

export async function presentLoading(message: string) {
	const loading = await loadingController.create({
		message: message,
	});
	await loading.present();

	return loading;
}

export async function presentAgLoading() {
	const loading = await popoverController.create({
		component: "present-loading",
		cssClass: "present-loading",
		translucent: true,
		backdropDismiss: false,
		animated: true,
		alignment: "center",
	});
	await loading.present();
	return loading;
}
