import { EntityFirestore, FileInfo } from "core/models";
import type {
	PayBalanceEntityData,
	PaymentMethod,
	SystemStatus,
} from "core/typings";

import { query, where, orderBy } from "firebase/firestore";
import { hasObjectChanged } from "shared/helpers";
import store from "global/store";

export class PayBalanceEntity extends EntityFirestore<PayBalanceEntityData> {
	static readonly collPath: string = "pay_balances";
	static readonly usesSubscription = true;

	status: SystemStatus;
	filesInfo: FileInfo[];

	paymentDate: Date;
	paymentAmount: number;
	paymentMethod: PaymentMethod;
	descriptions: string;

	createdByName: string;
	createdBy: string;
	createdAt: Date;

	constructor(data: PayBalanceEntityData) {
		super(data);

		this.status = data.status;
		this.filesInfo = data.filesInfo.map((fileInfo) => new FileInfo(fileInfo));

		this.paymentDate = new Date(data.paymentDate);
		this.paymentAmount = data.paymentAmount;
		this.paymentMethod = data.paymentMethod;
		this.descriptions = data.descriptions;

		this.createdByName = data.createdByName;
		this.createdBy = data.createdBy;
		this.createdAt = new Date(data.createdAt);
	}

	static get cachedCollRef() {
		if (store.user === null) {
			return null;
		}

		let userQuery = query(this.collRef);

		switch (store.user.role) {
			case "admin": {
				userQuery = query(
					userQuery,
					where("status", "==", "PENDING"),
					orderBy("createdAt", "desc"),
				);
				break;
			}
			default: {
				return null;
			}
		}

		return userQuery;
	}

	static cachedItemsUpdate() {
		if (
			store.payBalances.length === 0 ||
			hasObjectChanged(this.cachedItems, store.payBalances)
		) {
			store.payBalances = this.cachedItems.map((item) => item.clone());
		}
	}

	static convertTimestampsFromFirestore(data) {
		super.convertTimestampsFromFirestore(data);

		data.filesInfo = data.filesInfo.map((fileInfo) => {
			fileInfo.fileUploadDate = fileInfo.fileUploadDate.toDate();
			return fileInfo;
		});

		data.createdAt = data.createdAt.toDate();
		data.paymentDate = data.paymentDate.toDate();
	}
}
